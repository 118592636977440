import React from "react";
import { Link } from 'gatsby';
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout pageTitle="Page not found">
    <div className='text-center add-padding-2'>
        <h2 className='margin-bottom-2' style={{marginTop: 32}}>PAGE NOT FOUND</h2>
        <p style={{maxWidth: 450, margin: '16px auto 32px auto'}}>Sorry for the trouble, but this page does not exist or has been moved from this location.</p>
        <Link to="/" className='btn primary-outline-btn'>BACK TO HOME PAGE</Link>
    </div>
  </Layout>
)

export default NotFoundPage
